@font-face {
  font-family: 'SamsungOne400';
  src:  url('../fonts/SamsungOne-400_v1.0.woff') format('woff');
}
@font-face {
  font-family: 'SamsungOne700';
  src:  url('../fonts/SamsungOne-700_v1.0.woff') format('woff');
}
@font-face {
  font-family: 'SamsungSharpSans-Bold';
  src:  url('../fonts/SamsungSharpSans-Bold.woff') format('woff');
}

$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;

$primary: #007ac2 !default;
$info: #00b4ed !default;
$light: #7967ae !default;
$dark: #18398a !default;

// Options
$enable-rounded: false !default;
//$enable-gradients: true !default;
$spacer: 15px !default;

$grid-gutter-width: 60px !default;

$body-bg: #ffffff;
$body-color: #474747 !default;

// Grid containers

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  //xl: 1140px
  xl: 960px
  ) !default;


$font-family-sans-serif: 'SamsungOne400', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$headings-font-family: 'SamsungOne700', sans-serif !default;
$headings-color: $primary !default;

// Inputs
$input-bg: $white !default;
$input-color: $gray-600 !default;
$input-border-color: $primary !default;
$input-btn-padding-y: .5rem !default;


// Btns
//$btn-font-size: 1.5rem !default;
//$btn-padding-y: 0 !default;
//$btn-padding-x: 1.5rem !default;
$btn-disabled-opacity: .25 !default;

// Cards
//$card-color:                        $white !default;
$card-bg:                           transparent !default;
//$card-bg-over:                      $light !default;
$card-border-width:                 0 !default;
