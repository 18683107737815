body {
  &.home {
    background-image: url("../img/bg-home.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    footer {
      color: $white;
      a {
        &:hover {
          color: $white;
        }
      }
    }

    @include media-breakpoint-down(md) {
      background: $white;
      header {
        display: none;
        margin: 0;
      }
      footer {
        color: $body-color;
        a {
          &:hover {
            color: $dark;
          }
        }
      }
    }
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;

  .header-mobile {
    display: none;
    text-align: center;
    .mobile-hero {
      max-width: 100%;
      width: 100%;
      height: auto;
    }
    .mobile-logo {
      max-width: 260px;
      margin-top: 2rem;
      margin-bottom: 2rem;
    }
  }

  @include media-breakpoint-down(sm) {
    .header-mobile {
      display: block;
    }
  }
}

header {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

footer {
  //color: $white;
  text-align: center;

  @include media-breakpoint-down(md) {
    @include font-size(14px);
  }
}

a {
  font-weight: 700;
  text-decoration: underline;

  &.btn {
    text-decoration: none;
  }
}

label {
  margin-bottom: 0;
  @include media-breakpoint-down(sm) {
    @include font-size(12px);
  }
}

h2, h3, h4 {
  font-weight: bold;
  //text-transform: uppercase;
}

h5 {
  color: $body-color;
}

@include media-breakpoint-down(sm) {
  h2 {
    @include font-size(24px);
  }
  p {
    //@include font-size(12px);
  }
}

.header-products {
  background-image: url("../img/header.png");
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0;
  height: 208px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.header-register {
  background-image: url("../img/header-order.png");
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.logo {
  width: 100%;
  height: auto;
  max-width: 282px;
  margin-left: auto;
  margin-right: auto;

  @include media-breakpoint-down(sm) {
  }
}

.bg-overlay {
  //background-color: rgba(255, 255, 255, .75);
  background-color: $white;
  color: $body-color;
  padding-top: $spacer;
  padding-bottom: $spacer*2;

  @include media-breakpoint-down(sm) {
    padding-bottom: $spacer/2;
  }
}

.grid-item {
  width: 33%;

  @include media-breakpoint-down(md) {
    //width: 50%;
  }

  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

.card {
  &.card-product {
    text-align: center !important;
    padding: $spacer*2;
    margin-bottom: 0;
    //height: 100%;

    /*.card-img-top {
      //padding-bottom: 1rem;
      //padding: $spacer;
      height: 290px;
      display: flex;
      align-items: flex-end;
      justify-content: center;
    }*/

    .card-body {
      padding: 0;
      padding-top: 1.5rem;

      .card-title {
        @include font-size(16px);
        color: $primary;
      }
      .card-short {
        @include font-size(14px);
      }
      .card-description {
        @include font-size(14px);
        ul {
          padding-left: 1rem;
          text-align: left;
          font-size: inherit;
        }
      }
      .card-btn {
        //background-color: $white;
        padding-left: 2rem;
        padding-right: 2rem;
      }
    }

    @include media-breakpoint-down(sm) {
      height: auto;
      padding: 0;
      margin-bottom: 2rem;

      .card-img-top {
        height: auto;
      }
    }
  }
}

.form-home {
  .form-group {
    margin-bottom: 0;
  }
}
.form-register {
  label {
    text-transform: uppercase;
    small {
      text-transform: none;
    }
  }
}
.form-group {
  //margin-bottom: .5rem;
  @include media-breakpoint-down(sm) {
    margin-bottom: 0;
  }
}

.btn {
  font-family: $headings-font-family;
  text-transform: uppercase;
  font-weight: bold;

  &.btn-outline-primary {
    &:hover {
      color: darken($primary, 10%);
      //background-color: $card-bg-over;
    }
  }
}

.text-black {
  color: $black;
}
.font-alt {
  font-family: 'SamsungSharpSans-Bold';
}
.grecaptcha-badge {
  visibility: hidden;
}
